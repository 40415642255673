import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from 'axios'
import "element-ui/lib/theme-chalk/index.css";


import "./plugins/element.js";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.config.productionTip = false;
Vue.prototype.$axios = axios 
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
