<template>
  <div id="app">
     <Header></Header>
    <router-view />
     
  </div>
</template>

<script>
// import HelloWorld from "./components/HelloWorld.vue";
import Header from "./components/header"
export default {
  name: "app",
  components:{
    Header
  }
 
};
</script>

<style>
*{
  padding:0;
  margin:0
}
.banner {
  width: 100%;
  display: block;
}
.clear:after {
  content: ".";
  clear: both;
  height: 0;
  visibility: hidden;
  display: block;
}
</style>
