<template>
  <div>
    <div>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            v-for="(item, index) in zoneList.index_banner"
            class="swiper-slide"
            :key="index"
          >
            <img style="width: 100%" :src="item" />
          </div>
        </div>
        <!-- 如果需要分页器 -->
        <div class="swiper-pagination"></div>
      </div>
    </div>
    <div class="top-box" v-if="false">
      <img
        class="banner"
        src="http://youppie.doingforce.net/20200806_110242_67443058.jpg"
      />
      <!-- <div class="menu">
        <span @click="winter()" class="menus">{{ fornt }}</span>
        <span @click="summer()" class="menus menus-right">{{ next }}</span>
      </div> -->
    </div>
    <div style="text-align: center">
      <el-image
        lazy
        class="banner"
        :src="zoneList.index_about"
      ></el-image>
      <!-- <el-image
        lazy
        class="banner-phone"
        src="https://image.youppie.com/about.png"
      ></el-image> -->
      <el-image
        lazy
        class="banner"
        :src="zoneList.index_about"
      ></el-image>
    </div>
    <ul class="clear">
      <li class="my-li" v-for="(item, index) in zoneList.index_content" :key="index">
        <img :src="item" />
        <!-- <el-image
           lazy
        :src="item"
      ></el-image> -->
      </li>
    </ul>
    <!-- <div>
  <el-image
  lazy
        v-for="(item, index) in list"
        :key="index"
        class="banner"
        :src="item"
      ></el-image>
    </div> -->
    <div>
      <ul class="clear menu-ul">
        <li @click="winter()">{{ fornt }}</li>
        <li @click="summer()" class="clear">
          {{ next }}
          <span style="float: right">></span>
        </li>
      </ul>
    </div>
    <div>
      <img
        class="banner"
        :src="zoneList.index_store"
      />
    </div>
    <Foot></Foot>
    <el-backtop target="" :bottom="100" :visibility-height="300">
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: black;
            padding: 4px;
          }
        "
      >
        Top
      </div>
    </el-backtop>
  </div>
</template>

<script>
import Swiper from "swiper";
import Foot from "../components/foot";
var CryptoJS = require("crypto-js");
// import "../plugins/crypto.js"
export default {
  components: {
    Foot,
  },
  data() {
    return {
      bannerList: [
        "https://image.youppie.com/%E7%94%B5%E8%84%91%E7%AB%AF_01.jpg",
        "https://image.youppie.com/%E7%94%B5%E8%84%91%E7%AB%AF1_01.jpg",
      ],
      newImg: [
        "https://image.youppie.com/1.jpg",
        "https://image.youppie.com/2.jpg",
        "https://image.youppie.com/3.jpg",
        "https://image.youppie.com/4.jpg",
        "https://image.youppie.com/5.jpg",
        "https://image.youppie.com/6.jpg",
        "https://image.youppie.com/7.jpg",
        "https://image.youppie.com/8.jpg",
        "https://image.youppie.com/9.jpg",
        "https://image.youppie.com/10.jpg",
        "https://image.youppie.com/11.jpg",
        "https://image.youppie.com/12.jpg",
        "https://image.youppie.com/13.jpg",
        "https://image.youppie.com/14.jpg",
        "https://image.youppie.com/15.jpg",
        "https://image.youppie.com/16.jpg",
        "https://image.youppie.com/17.jpg",
        "https://image.youppie.com/18.jpg",
        "https://image.youppie.com/19.jpg",
        "https://image.youppie.com/20.jpg",
        "https://image.youppie.com/21.jpg",
        "https://image.youppie.com/22.jpg",
        "https://image.youppie.com/23.jpg",
        "https://image.youppie.com/24.jpg",
        "https://image.youppie.com/25.jpg",
        "https://image.youppie.com/26.jpg",
        "https://image.youppie.com/27.jpg",
        "https://image.youppie.com/28.jpg",
        "https://image.youppie.com/29.jpg",
        "https://image.youppie.com/30.jpg",
        "https://image.youppie.com/31.jpg",
        "https://image.youppie.com/32.jpg",
        "https://image.youppie.com/33.jpg",
        "https://image.youppie.com/34.jpg",
        "https://image.youppie.com/35.jpg",
        "https://image.youppie.com/36.jpg",
        "https://image.youppie.com/37.jpg",
        "https://image.youppie.com/38.jpg",
        "https://image.youppie.com/39.jpg",
        "https://image.youppie.com/40.jpg",
        "https://image.youppie.com/41.jpg",
        "https://image.youppie.com/42.jpg",
        "https://image.youppie.com/43.jpg",
      ],
      fornt: "2020 FALL/WINTER",
      next: "2020 SPRING SUMMER",
      list: [
        "http://youppie.doingforce.net/20201008_201617_71828015.jpg",
        "http://youppie.doingforce.net/20201008_201649_45481325.jpg",
        "http://youppie.doingforce.net/20201008_201731_70385205.jpg",
        "http://youppie.doingforce.net/20201008_201805_18786388.jpg",
        "http://youppie.doingforce.net/20201008_201902_51985045.jpg",
        "http://youppie.doingforce.net/20201008_201933_89567303.jpg",
        "http://youppie.doingforce.net/20201008_202011_52339193.jpg",
        "http://youppie.doingforce.net/20201008_202040_71639497.jpg",
        "http://youppie.doingforce.net/20200806_112232_47931562.jpg",
        "http://youppie.doingforce.net/20200806_112248_71571543.jpg",
        "http://youppie.doingforce.net/20200806_112310_89997276.jpg",
        "http://youppie.doingforce.net/20200806_112328_89925194.jpg",
        "http://youppie.doingforce.net/20200806_112343_77843809.jpg",
      ],
      zoneList: {},
    };
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: true, // 循环模式选项
      autoplay: {
        delay: 4000,
        stopOnLastSlide: false,
        disableOnInteraction: false,
        cirular: true,
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
    });
  },
  created() {
    var token = this.Encrypt(JSON.stringify(new Date().getTime()));
    // console.log(token);
    this.$axios({
      url: "https://admin.youppie.com/api/index/get_index",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => {
        // console.log(response);
        this.zoneList = response.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    Encrypt(word) {
      var IV = CryptoJS.enc.Utf8.parse("THISISAGOODDAY!!");
      var key = CryptoJS.enc.Utf8.parse("youppie000000000");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.CBC,
        iv: IV,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
    // 加密
    // encrypt(str) {
    //   var IV = 'THISISAGOODDAY!!'; // 偏移量
    //   var KEY = 'youppie000000000';// 密钥
    //   var key = CryptoJS.enc.Utf8.parse(KEY);
    //   var iv = CryptoJS.enc.Utf8.parse(IV);
    //   var encrypted = CryptoJS.AES.encrypt(str,key,{iv:iv,mode:CryptoJS.mode.CBC,padding:CryptoJS.pad.Pkcs7});
    //   return encrypted.toString();
    // },

    summer() {
      // winter
      let data = {
        list: [
          { src: "https://image.youppie.com/20200331_185650_11185980.jpg" },
          { src: "https://image.youppie.com/20200331_185651_87147627.jpg" },
          { src: "https://image.youppie.com/20200331_185651_82192520.jpg" },
          { src: "https://image.youppie.com/20200331_185651_90624524.jpg" },
          { src: "https://image.youppie.com/20200331_185651_82938786.jpg" },
          { src: "https://image.youppie.com/20200331_185651_79227906.jpg" },
          { src: "https://image.youppie.com/20200331_185652_55183959.jpg" },
          { src: "https://image.youppie.com/20200331_185652_83421855.jpg" },
          { src: "https://image.youppie.com/20200331_185652_50513006.jpg" },
          { src: "https://image.youppie.com/20200331_185652_53085695.jpg" },
          { src: "https://image.youppie.com/20200331_185727_65877736.jpg" },
          { src: "https://image.youppie.com/20200331_185727_86996107.jpg" },
          { src: "https://image.youppie.com/20200331_185727_96966406.jpg" },
          { src: "https://image.youppie.com/20200331_185727_48235958.jpg" },
          { src: "https://image.youppie.com/20200331_185728_75934324.jpg" },
        ],
        key: "summer",
      };
      this.$router.push({
        name: "show",
        query: { list: JSON.stringify(data) },
      });
    },
    winter() {
      let data = {
        list: [
          { src: "https://image.youppie.com/20200806_142417_58369159.jpg" },
          { src: "https://image.youppie.com/20200806_142417_83829113.jpg" },
          { src: "https://image.youppie.com/20200806_142417_58914269.jpg" },
          { src: "https://image.youppie.com/20200806_142417_85619509.jpg" },
          { src: "https://image.youppie.com/20200806_142417_72938783.jpg" },
          { src: "https://image.youppie.com/20200806_142417_24820830.jpg" },
          { src: "https://image.youppie.com/20200806_142418_92097289.jpg" },
          { src: "https://image.youppie.com/20200806_142418_70494644.jpg" },
          { src: "https://image.youppie.com/20200806_142422_41211960.jpg" },
          { src: "https://image.youppie.com/20200806_142422_78190786.jpg" },
          { src: "https://image.youppie.com/20200806_142503_61500658.jpg" },
          { src: "https://image.youppie.com/20200806_142506_70303212.jpg" },
          { src: "https://image.youppie.com/20200806_142506_91465651.jpg" },
          { src: "https://image.youppie.com/20200806_142506_97224642.jpg" },
          { src: "https://image.youppie.com/20200806_142506_47040073.jpg" },
          { src: "https://image.youppie.com/20200806_142506_54712378.jpg" },
          { src: "https://image.youppie.com/20200806_142506_97069222.jpg" },
        ],
        key: "winter",
      };
      this.$router.push({
        name: "show",
        query: { list: JSON.stringify(data) },
      });
    },
  },
};
</script>
<style scoped>
.clear:after {
  content: ".";
  clear: both;
  height: 0;
  visibility: hidden;
  display: block;
}

.top-box {
  position: relative;
}
.banner {
  width: 100%;
  display: block;
}
.menu {
  position: absolute;
  right: 15%;
  color: white;
  font-weight: bold;
  top: 0;
  line-height: 90px;
  z-index: 999;
  font-size: 24px;
}
.menus {
  cursor: pointer;
}
.menus-right {
  margin-left: 30px;
}
.menu-ul {
  width: 59%;
  margin: 70px auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.menu-ul li {
  width: 49%;
  float: left;
  line-height: 70px;
  color: black;
  font-weight: bold;
  font-size: 24px;
  list-style-type: none;
  padding-left: 30px;
  box-sizing: border-box;
  cursor: pointer;
}
.menu-ul li:nth-of-type(1) {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.my-li {
  width: 25%;
  float: left;
  list-style: none;
  font-size: 0;
  text-align: center;
  margin-top: 2px;
}
.my-li img {
  width: 99%;
}
.banner-phone {
  display: none;
  width: 80%;
  margin: 15px auto;
}
/* .my-li image{
  width:100%;
  display: block;
} */
@media screen and (max-width: 1220px) {
  .menu-ul {
    display: none;
  }
  .my-li {
    width: 50%;
  }
  .banner {
    display: none;
  }
  .banner-phone {
    display: block;
  }
}
</style>
