<template>
  <div>
    <div class="foot-box">
      <div class="title">CONTACT US</div>
      <ul class="foot-ul">
        <li @click="jump(item)" v-for="(item, index) in icon" :key="index">
          <img :src="item.icon" :class="'my-img' + index" />
        </li>
      </ul>
      <div style="font-size: 0; background: #e4e5e7">
        <img
          style="border: none"
          class="banner"
          :src="imgsand"
        />
      </div>
    </div>
    <div @click="goEvey()" class="police-num" style="text-align: center; line-height: 40px;cursor:pointer">
      {{textas}}
    </div>
  </div>
</template>
<script>
var CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      icon: [
        // { src: require("../assets/weixin.png"), link: "" },
        // { src: require("../assets/book.png"), link: "http://www.baidu.com" },
        // { src: require("../assets/douyin.png"), link: "http://www.baidu.com" },
        // { src: require("../assets/weibo.png"), link: "http://www.baidu.com" },
        // { src: require("../assets/cat.png"), link: "http://www.baidu.com" },
        // { src: require("../assets/xiangji.png"), link: "http://www.baidu.com" },
      ],
      textas: "",
      imgsand:""
    };
  },
  created() {
    var token = this.Encrypt(JSON.stringify(new Date().getTime()));
    // console.log(token);
    this.$axios({
      url: "https://admin.youppie.com/api/index/get_foot",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => {
        // console.log(response.data.data);
        this.icon = response.data.data.icon;
        this.imgsand = response.data.data.foot_content;
        
      })
      .catch((error) => {
        console.log(error);
      });
    this.$axios({
      url: "https://admin.youppie.com/api/index/get_basic",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => {
        this.textas = response.data.data.beian;
        
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    jump(val) {
      val.link && window.open(val.link, "blank");
    },
    goEvey(){
      window.open('https://beian.miit.gov.cn/','blank')
    },
    Encrypt(word) {
      var IV = CryptoJS.enc.Utf8.parse("THISISAGOODDAY!!");
      var key = CryptoJS.enc.Utf8.parse("youppie000000000");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.CBC,
        iv: IV,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
  },
};
</script>
<style scoped>
.banner {
  width: 100%;
  display: block;
}
.foot-box {
  width: 100%;
  padding: 30px;
  background: #e4e5e7;
  box-sizing: border-box;
}
.title {
  font-size: 26px;
  font-weight: bold;
  line-height: 50px;
  text-align: center;
  color: black;
}
.foot-ul {
  width: 50%;
  margin: 50px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.foot-ul li {
  list-style: none;
  cursor: pointer;
}
.foot-ul li img {
  width: 42px;
}
.my-img1 {
  width: 60px !important;
}
@media screen and (max-width: 1220px) {
  .foot-box,
  .menu-ul {
    display: none;
  }
  .police-num {
    line-height: 30px !important;
    font-size: 15px;
  }
}
</style>