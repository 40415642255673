<template>
  <div>
    <div :class="scroll > 0 ? 'big-box-act' : 'big-box'">
      <ul :class="scroll > 0 ? 'clear my-ul-act' : 'clear my-ul'">
        <li style="margin-left: 0">
          <img
            @click="$router.push('/')"
            style="cursor: pointer; vertical-align: middle"
            :src="
              scroll > 0
                ? 'https://image.youppie.com/logo-dark.png'
                : 'https://image.youppie.com/logo-light.png'
            "
          />
        </li>
        <li @click="$router.push('/')">{{ shous }}</li>
        <li @click="winter()">{{ fornt }}</li>
        <li @click="summer()">{{ next }}</li>
      </ul>
    </div>
    <div class="small-btn">
      <img
        v-show="show"
        @click="drawer = true"
        style="width: 30px"
        :src="require('../assets/ban.png')"
      />
    </div>
    <el-drawer
      title="我是标题"
      size="55%"
      :visible.sync="drawer"
      :with-header="false"
    >
      <ul class="inner-ul">
        <li
          @click="
            $router.push('/');
            drawer = false;
          "
        >
          {{ shous }}
        </li>
        <li @click="winter()">{{ fornt }}</li>
        <li @click="summer()">{{ next }}</li>
      </ul>
    </el-drawer>
  </div>
</template>
<script>
var CryptoJS = require("crypto-js");
export default {
  data() {
    return {
      shous: "",
      fornt: "",
      next: "",
      scroll: "",
      drawer: false,
      show: true,
    };
  },
  watch: {
    drawer(val) {
      this.show = !val;
    },
  },
  beforeCreate() {
    // console.log(this.$route)
  },
  mounted() {
    window.addEventListener("scroll", this.getScroll);
  },
  created() {
    var token = this.Encrypt(JSON.stringify(new Date().getTime()));
    // console.log(token);
    this.$axios({
      url: "https://admin.youppie.com/api/index/get_column",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        token: token,
      },
    })
      .then((response) => {
        this.shous = response.data.data.page_index.title;
        this.fornt = response.data.data.page_single1.title;
        this.next = response.data.data.page_single2.title;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    summer() {
      // summer
      let data = {
        key: "summer",
      };

      // console.log(this.$route.query)
      if (this.$route.query.key === "summer" && this.$route.path === "/show") {
        return false;
      } else if (this.$route.query.key != "summer" && this.$route.path === "/show") {
        window.location.href = window.location.href.split("?")[0] + "?list=" + JSON.stringify(data);
        window.location.reload();
      } else {
        this.$router.push({
          name: "show",
          query: { list: JSON.stringify(data) },
        });
        this.drawer = false;
      }
    },



    winter() {
      let data = {
        key: "winter",
      };
      // console.log(this.$route.query)
      if (this.$route.query.key === "winter" && this.$route.path === "/show") {
        // console.log("原页面");
        return false;
      } else if (this.$route.query.key != "winter" && this.$route.path === "/show") {
        // console.log("只需要改数据");
        window.location.href = window.location.href.split("?")[0] + "?list=" + JSON.stringify(data);
        window.location.reload();
      } else {
        // console.log("可以跳");
        this.$router.push({
          name: "show",
          query: { list: JSON.stringify(data) },
        });
      }
      this.drawer = false;
    },
    getScroll() {
      this.scroll =
        document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll);
    },
    Encrypt(word) {
      var IV = CryptoJS.enc.Utf8.parse("THISISAGOODDAY!!");
      var key = CryptoJS.enc.Utf8.parse("youppie000000000");
      var srcs = CryptoJS.enc.Utf8.parse(word);
      var encrypted = CryptoJS.AES.encrypt(srcs, key, {
        mode: CryptoJS.mode.CBC,
        iv: IV,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    },
  },
};
</script>
<style scoped>
.big-box-act {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  background: white;
  border-bottom: 1px solid #ddd;
}
.small-btn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 9;
  display: none;
  text-align: right;
}
.big-box {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 9;
  background-color: rgba(255, 255, 255, 0);
}

.my-ul li {
  list-style: none;
  float: left;
  color: white;
  font-weight: bold;
  cursor: pointer;
  line-height: 90px;
  margin-left: 8%;
  /* margin-right:10%; */
  font-size: 24px;
}
.my-ul {
  width: 90%;
  /* margin:0 auto */
  float: right;
}
.my-ul-act {
  /* width: 90%; */
  width: 90%;
  float: right;
  /* margin:0 auto */
}
.my-ul-act li {
  list-style: none;
  float: left;
  color: #1f1f21;
  font-weight: bold;
  cursor: pointer;
  line-height: 90px;
  margin-left: 8%;
  /* margin-right:10%; */
  font-size: 24px;
}
.inner-ul li {
  margin-top: 18px;
  padding-left: 10px;
  cursor: pointer;
}
@media screen and (max-width: 1220px) {
  .big-box-act,
  .big-box {
    display: none;
  }
  .small-btn {
    display: block;
  }
}
</style>